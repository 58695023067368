import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Routes from "./Routes";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { Auth } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import { AppContext } from "./libs/contextLib";
import { onError } from "./libs/errorLib";
import "./App.css";

import thumb from "./images/scape_thumb.png";

function App() {
  const history = useHistory();
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);

  useEffect(() => {
    onLoad();
  }, []);

  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        onError(e);
      }
    }

    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);

    history.push("/login");
  }

  return (
    !isAuthenticating && (
      <div className="app-container">
        <div className="top-header">
        <Navbar collapseOnSelect fixed="top" sticky-top expand="sm" className="mb-0">
          <LinkContainer to="/">
            <Navbar.Brand className="font-weight-bold">
              <div className="logo-nav">SCAPE</div>
              <img className="thumb-logo-nav" src ={thumb} alt="thumbprint to represent BIPOC identity" />
            </Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle />
          <Navbar.Collapse className="justify-content-end">
            <Nav activeKey={window.location.pathname}>
              <LinkContainer to="/projects">
                <Nav.Link>Projects</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/artists">
                <Nav.Link>Artists</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
        </div>

        <AppContext.Provider value={{ isAuthenticated, userHasAuthenticated }}>
          <Routes />
        </AppContext.Provider>

      </div>
    )
  );
}

export default App;



// {isAuthenticated ? (
//   <>
//     <LinkContainer to="/settings">
//       <Nav.Link>Settings</Nav.Link>
//     </LinkContainer>
//     <Nav.Link onClick={handleLogout}>Logout</Nav.Link>
//   </>
// ) : (
//   <>
//     <LinkContainer to="/login">
//       <Nav.Link>Login</Nav.Link>
//     </LinkContainer>
//   </>
// )}
