import React from "react";

import "./Shop.css";

import hoodie from '../images/hoodie.jpg';
import remi from '../images/remi_empower.jpg';
import bernice_trinity from "../images/bernice_trinity.jpg";


export default function Shop() {
  return (
    <div className="shop-wrapper">
      <div className="shop-box">
        <div className="shop-header">
          Shirts, Hoodies and Prints available at project work sites.
        </div>
        <div className="shop-copy">
          June 5th - June 13th, we will be working outside Sonoma Originals in Boyes Hot Springs (17400 Hwy 12). Come by to check out the work in progress and pick up some merch.
        </div>
        <div className="shop-copy">
          ... or <a href="mailto:shopping@scapemurals.org">contact us</a> for local pickup/delivery.
        </div>
        <div className="shop-img-box">
          <img src={hoodie} className="shop-img-sm" alt="SCAPE logo hoodie"  />
          <img src={remi} className="shop-img-sm" alt="SCAPE logo tank top"  />
          <img src={bernice_trinity} className="shop-img-sm" alt="print of Bernice Espinoza mural"  />
        </div>
      </div>
    </div>

  );

}
