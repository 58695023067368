import React, { useState, useEffect } from "react";
import ListGroup from "react-bootstrap/ListGroup";
import { useAppContext } from "../libs/contextLib";
import { onError } from "../libs/errorLib";
import { API } from "aws-amplify";
import { BsPencilSquare } from "react-icons/bs";
import { LinkContainer } from "react-router-bootstrap";
import Nav from "react-bootstrap/Nav";

import "./Home.css";
import rima_support from '../images/rima_support.jpg';
import fevi_support from '../images/fevi_support.jpg';
import skate_mural from '../images/skate_mural.jpeg';
import trinity_thumb from '../images/trinity_thumb.jpg';
import ana_slagado_thumb from '../images/ana_slagado_thumb.jpg';
import shirt_print_thumb from '../images/shirt_print_thumb.jpg';
import sweater_thumb from '../images/sweater_thumb.jpg';
import fists_thumb from '../images/fists_thumb.jpg';


//   To Do
// Bring in borders for web view... 600px. make images smaller   make text boxes more like caption bubbles.
// Swap photo of rosie on trinity page
//set up email forwarding in Aws

export default function Home() {
  const [notes, setNotes] = useState([]);
  const { isAuthenticated } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
  async function onLoad() {
    if (!isAuthenticated) {
      return;
    }

    try {
      const notes = await loadMerch();
      setNotes(notes);
    } catch (e) {
      onError(e);
    }

    setIsLoading(false);
  }

  onLoad();
}, [isAuthenticated]);
// Move to Merch page
function loadMerch() {
  return API.get("merch", "/merch");
}

// Move to Merch page
function renderNotesList(notes) {
return (
  <>
    <LinkContainer to="/notes/new">
      <ListGroup.Item action className="py-3 text-nowrap text-truncate">
        <BsPencilSquare size={17} />
        <span className="ml-2 font-weight-bold">Create a new note</span>
      </ListGroup.Item>
    </LinkContainer>
    {notes.map(({ merchId, content, createdAt }) => (
      <LinkContainer key={merchId} to={`/notes/${merchId}`}>
        <ListGroup.Item action>
          <span className="font-weight-bold">
            {content.trim().split("\n")[0]}
          </span>
          <br />
          <span className="text-muted">
            Created: {new Date(createdAt).toLocaleString()}
          </span>
        </ListGroup.Item>
      </LinkContainer>
    ))}
  </>
);
}


//{isAuthenticated ? renderNotes() : renderLander()}
  function renderNotes() {
    return (
      <div className="notes">
        <h2 className="pb-3 mt-4 mb-3 border-bottom">Your Notes</h2>
        <ListGroup>{!isLoading && renderNotesList(notes)}</ListGroup>
      </div>
    );
  }

  return (
    <div className='home-wrapper'>

      <div className="home-dt-container">

        <div className="dt-card">
          <LinkContainer to="/bhsmural" className="home-card-dt-link">
            <Nav.Link>
              <div className="dt-card-title">
                Recent Project! June 2021
              </div>
              <div className="dt-card-image-box">
                <img className="img-fluid dt-card-image-ls" src={skate_mural} alt="mural for skate shop" />
              </div>
              <div className="dt-card-copy">
                <div className="dt-card-copy-header">
                  Boyes Hot Springs Mural
                </div>
                Come check out Scape and Monarch Project's most recent mural in Boyes Hot Springs.
                It features D'mitra Smith, a powerful force fighting against racism in Sonoma County.
                 It is located on the wall of Sonoma Originals at 17400 Sonoma Hwy.
                <div className="dt-card-copy-header">
                  VOLUNTEER &emsp;   DONATE
                </div>
              </div>
            </Nav.Link>
          </LinkContainer>
        </div>

        <div className="dt-card-ls-right">

        <LinkContainer to="/artists" className="home-card-dt-link">
          <Nav.Link>

          <div className="dt-card-inner">
            <img src={rima_support} className="support-thumb" alt="rima makaryan painting"/>
            <img src={fevi_support} className="support-thumb" alt="fevi zee painting"/>
            <div className="dt-card-inner-title ml-3">
              The Artists We Support
            </div>
          </div>
          </Nav.Link>
        </LinkContainer>

          <div className="dt-card-inner-line"></div>

          <LinkContainer to="/projects" className="home-card-dt-link">
            <Nav.Link>

            <div className="dt-card-inner mt-3">
              <div className="dt-card-inner-title mr-4">
                Projects
              </div>
              <img src={trinity_thumb} className="support-thumb" alt="trinity murals"/>
              <img src={ana_slagado_thumb} className="support-thumb dt-hide-mobile"  alt="portrait of Ana Slagado"/>
            </div>
            </Nav.Link>
          </LinkContainer>

            <div className="dt-card-inner-line"></div>

            <LinkContainer to="/shop" className="home-card-dt-link">
              <Nav.Link>
            <div className="dt-card-inner mt-3">
              <img src={shirt_print_thumb} className="support-thumb" alt="scape branded shirt"/>
              <div className="dt-card-inner-title mr-3">
                Shop and Support
              </div>
              <img src={sweater_thumb} className="support-thumb dt-hide-mobile" alt="scape branded sweater"/>
            </div>
            </Nav.Link>
          </LinkContainer>
            <div className="dt-card-inner-line"></div>

            <LinkContainer to="/contact" className="home-card-dt-link">
              <Nav.Link>
            <div className="dt-card-inner mt-3">
              <div className="dt-card-inner-title mr-3">
                Reach Out!  &emsp;Contact SCAPE
              </div>
              <img src={fists_thumb} className="support-thumb" alt="BLM Fist and LGBTQ Fist"/>
            </div>
            </Nav.Link>
          </LinkContainer>
        </div>

        <div className="dt-mission-box">
          <div className="mission-header">
            Sonoma County Artists Propelling Equity.
          </div>
            <b>SCAPE</b>, is a cooperative developed to uplift <b>Immigrants</b> and <b>People of Color</b> in Sonoma County.
            We support artists who challenge systems of oppression and highlight the inherent <b>beauty</b> of diversity.
        </div>


      </div>
    </div>

  );
}
