import React from "react";

import "./Projects.css";
import trinity from "../../images/trinity_project.jpg";
import bernice_trinity from "../../images/bernice_trinity.jpg";
import rose_trinity from "../../images/rose_trinity.jpg";
import joy_trinity from "../../images/joy_trinity.jpg";
import bernice_mall from "../../images/bernice_mall.jpg";
import plaza from "../../images/SR_plaza.jpg"
import fevi_OO from "../../images/fevi_OO.jpg";
import butterfly from "../../images/OO_pink_butterfly.jpg";
import lnl from "../../images/OO_LnL.jpg";
import red_bear from "../../images/OO_red_bear.jpg";
import woman_painting from "../../images/OO_woman_painting.jpg";
import panel from "../../images/OO_panel.jpg";
import guy_painting_blue from "../../images/OO_guy_painting_blue.jpg";

export default function Projects() {
  return (
    <div className="projects-wrapper">

      <div className="p-card">
        <div className="p-card-img-top">
          <img src={trinity} className="p-card-img" alt="rima makaryan"  />
        </div>
        <div className="p-card-title mt-4">
          Trinity Mural
        </div>
        <div className="p-card-msg-box">
           <img src={bernice_mall}  alt="13 foot tall portrait of Bernice Espinoza at Santa Rosa Mall" className="p-card-msg-img" />
           <div className="p-card-msg-header">
              Now on Display at the Santa Rosa Plaza!
           </div>
           <img src={plaza} alt="Santa Rosa Plaza Facade" className="plaza-img" />
           <div className="p-card-msg-copy">
              Upstairs in the Mall, next to Wetzel's Pretzels
           </div>
        </div>
      </div>

      <div className="p-card p-card-right">
        <div className="p-card-title">
          Trinity Mural
        </div>
        <div className="p-card-img-top mt-2">
          <img src={trinity} className="p-card-img" alt="rima makaryan"  />
        </div>
        <div className="p-card-copy">
          &emsp; The <b>Trinity Mural</b> features 13 foot tall portraits of three powerful Sonoma County BIPOC leaders whose
          activism deserves attention.  The mural is currently installed upstairs in the Santa Rosa Plaza.
        </div>
        <img className="img-fluid resize rounded trinity-img  mt-3" src={bernice_trinity} alt="Painting of Bernice Espinoza" />
        <div className="p-card-copy">
          &emsp;<b>Bernice Espinoza</b> is an attorney who fights for immigrant rights advocating
          for racial justice and criminal justice reform.
        </div>
        <img className="img-fluid resize rounded trinity-img mt-3" src={rose_trinity} alt="Painting of Rose Hammock" />
        <div className="p-card-copy">
          &emsp;<b>Rose Hammock</b> is an educator and activist in our local Indigenous Community.
          Rose is Pomo and Wailacki and is keeping the traditions of her people alive by passing on
          her knowledge to youth and continuing to look to her elders.
        </div>
        <img className="img-fluid resize rounded trinity-img mt-3" src={joy_trinity} alt="Painting of Joy Ayodele" />
        <div className="p-card-copy">
        &emsp;<b>Joy Ayodele</b> is a student activist who has become a youth leader in the local Black Lives Matter movement.
        “I am truly touched beyond words. I am honored. Sometimes there are moments of discouragement and fatigue,
        so it’s really special to me to feel uplifted in this way,”
        said Joy when she was told her portrait would be featured in our first mural.
        </div>

      </div>

      <div className="p-card">
        <div className="p-card-title">
          S.R. Open & Out
        </div>
        <div className="p-card-OO-img-top">
          <img src={fevi_OO} className="p-card-img" alt="Fevi Zee painting on 4th street"  />
        </div>
        <div className="p-card-OO-copy">
          &emsp;Santa Rosa’s <b>Open & Out</b> is a program that has turned sections of <b>4th Street</b> into pedestrian
          only zones, revitalized the Downtown area and brought in dozens of local artists to install
          temporary pieces.
          <br />
          &emsp; We were invited to participate and created <b>A Tapestry of our People</b>. It
          features imagery that reflects our community—mandalas painted on the ground that tell the
          story of Indigenous people, the migration of Latin Americans, the story of Black people
          in the U.S. and of LGBTQ+ people.
        </div>
        <img src={butterfly} className="OO-img-sm OO-mobile-80" alt="monarch butterfly in mandala painted on 4th st"  />
        <div className="p-card-OO-copy OO-mobile-100">
          &emsp;The <b>portraits</b> feature local activists-- Ana Salgado,
          Tre Vasquez, Marqus Redbear Martinez, Tavy Tornado, Devin Ariel, Kimmie Barbosa, Rafael
           Vasquez and Delashay Carmona Benson.
        </div>
        <div className="OO-img-box">

          <img src={red_bear} className="OO-img-sm" alt="Love and Light Activist Leaders"  />
          <img src={panel} className="OO-img-sm" alt="Love and Light Activist Leaders"  />
          <img src={woman_painting} className="OO-img-sm" alt="Love and Light Activist Leaders"  />
          <img src={guy_painting_blue} className="OO-img-sm" alt="Love and Light Activist Leaders"  />
          <img src={lnl} className="OO-img-sm" alt="Love and Light Activist Leaders"  />
        </div>
      </div>


    </div>
  );
}
