const config = {

  STRIPE_KEY: "pk_test_51HtFvZE3NieLns2UXbGszBxjIkaUvi8TmDOYgMEdaxqM2g1ZoKqCpbxZiTKG8Ao3KLMx8VblUU7Z9OkrMAOzp4zE00sJUsMyw1",
  MAX_ATTACHMENT_SIZE: 5000000,

  s3: {
    REGION: "us-west-2",
    BUCKET: "scape-upload",
  },
  apiGateway: {
    REGION: "us-west-2",
    URL: "https://zsrbcjhcx9.execute-api.us-west-2.amazonaws.com/prod",
  },
  cognito: {
    REGION: "us-west-2",
    USER_POOL_ID: "us-west-2_8Bz24XjBb",
    APP_CLIENT_ID: "3tegeolq04rdkdig2thtnhsj5m",
    IDENTITY_POOL_ID: "us-west-2:32f0d606-92c0-4734-880d-38ac706c65c3",
  },
};

export default config;
