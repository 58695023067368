import React from "react";

import "./Artists.css";

import rima from "../images/rima_headshot.jpg";
import rima_painting from "../images/rima_painting_portrait.jpg";
import rima_support from '../images/rima_support.jpg';
import fevi from '../images/fevi_headshot.jpeg';
import fevi_support from '../images/fevi_support.jpg';
import fevi_painting_trinity from '../images/fevi_painting_trinity.jpg';
import vanessa from '../images/vanessa_headshot.jpg';
import vanessa_painting_pink from '../images/vanessa_artists_pic.jpg';
import vanessa_OO from '../images/vanessa_OO.jpg';
import question from '../images/thumb_question.png';
import fist from '../images/black_fist.png';


export default function Artists() {
  return (
    <div className="artist-wrapper">

      <div className="a-card">
        <div className="a-card-img-box">
          <img src={rima} className="a-card-img" alt="rima makaryan"  />
          <img src={rima_painting} className="a-card-img-sm" alt="rima makaryan"  />
            <img src={rima_support} className="a-card-img-sm" alt="rima makaryan"  />
        </div>
        <div className="a-card-title">
          RIMA MAKARYAN
        </div>
        <div className="a-card-copy">
          &emsp; <b>Rima Makaryan</b> is a muralist and graphic designer. She established a youth based
         “artivist" organization called <b>The Monarch Project</b>.  Her work through The Monarch Project is inspired by the human
         experience within the context of social justice.  It is a vehicle to highlight the struggles, beauty, strength
         and vulnerability of those fighting for their rights, those fighting for their freedoms, and those fighting for the ability to live
         their lives unapologetically and fearlessly.
         <br />
           &emsp; Her interest in <b>activism and art</b> takes root in her personal story as an immigrant from Armenia:
         the fight to take pride in herself as a middle-eastern woman in the United States and the struggle of her family
         to overcome the social, cultural, economic, and political barriers they have encountered.
              <br />
            &emsp;Rima is primarily and proudly a self-taught artist, but has also completed the higher level
         International Baccalaureate program in Visual Arts. She worked with numerous arts organizations
          in Sonoma County, including Art Start Santa Rosa and the Petaluma Arts Center before founding "The Monarch Project."
          Rima is <b>currently studying</b> at Stanford University through the ITALIC program
         (Immersion in the Arts: Living in Culture, a residence based program).
           <br />  <br />
         Check out<a href="https://www.socoimm.org/"> The Monarch Project</a>.
        </div>
      </div>

      <div className="a-card a-card-right">
        <div className="a-card-img-box">
          <img src={fevi} className="a-card-img" alt="rima makaryan"  />
          <img src={fevi_support} className="a-card-img-sm" alt="rima makaryan"  />
            <img src={fevi_painting_trinity} className="a-card-img-sm" alt="rima makaryan"  />
        </div>
        <div className="a-card-title">
          Fevi Zee
        </div>
        <div className="a-card-copy">
          &emsp; <b>Fevi Zee</b> is a first-generation Eritrean immigrant. She creates artwork to stir interest
                   in the fight for racial equity and justice. “<b>My community</b> takes up notable residence here in Sonoma
                   County. It’s an honor for me to give my best for my tribe,” she said. “Through my efforts with SCAPE
                    I hope to spark curiosity, social and political awakening that push for <b>collective solution-building </b>
                     that will impact socio-economic improvements for BIPOC communities.”
         </div>
      </div>

      <div className="a-card a-card-right">
        <div className="a-card-img-box">
          <img src={vanessa} className="a-card-img" alt="rima makaryan"  />
          <img src={vanessa_painting_pink} className="a-card-img-sm" alt="rima makaryan"  />
            <img src={vanessa_OO} className="a-card-img-sm" alt="rima makaryan"  />
        </div>
        <div className="a-card-title">
          Vanessa May
        </div>
        <div className="a-card-copy">
          &emsp; <b>Vanessa May</b> is a POC creative and musician based near the Sonoma Coast. Her music and works are
                informed by <b>interrogations and intersections</b> between the cultural and identity markers that
                bind us together and pull us apart.”
         </div>
      </div>

      <div className="a-card">
        <div className="a-card-img-box">
          <img src={question} className="a-card-img" alt="rima makaryan"  />
          <img src={fist} className="a-card-img-sm" alt="rima makaryan"  />

        </div>
        <div className="a-card-title">
          Got a Project?
        </div>
        <div className="a-card-copy">
          &emsp;If you need <b>help producing </b> a public creative project and are a <b>BIPOC</b> with a connection to Sonoma County, contact SCAPE with your project proposal.
          <br />
          <br />
          email: <a href="mailto:artists@scapemurals.org">artists@scapemurals.org</a>
         </div>
      </div>





    </div>


  );
}
