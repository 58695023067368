import React from "react";

import "./Contact.css";

import volunteers from "../images/trinity_sebrd_vlntrs.jpg";
import group from "../images/OO_blue_circle.jpg";

export default function Contact() {
  return (
    <div className="contact-wrapper">
      <div className="contact-box">
        <img src={volunteers} className="contact-img-top" alt="volunteers painting 4th street" />
        <img src={group} className="contact-img-top contact-no-mobile" alt="volunteers painting rose mural" />
        <div className="contact-copy">
          <b>SCAPE</b> is a <b>volunteer</b> run orginization.  If you need assistance with a public <b>project</b>, have <b>time</b> to volunteer or <b>money</b> to donate please reach out: &emsp;
          <a href="mailto:info@scapemurals.org">info@scapemurals.org</a>
        </div>

      </div>
    </div>

  );

}
