import React from "react";

import "./BHSMural.css";
import skate_mural from '../../images/skate_mural.jpeg';
import mural from '../../images/dmitria_mural_1.jpg';

export default function BHSMural() {
  return (
    <div className="BHS-container">
      <div className="BHS-box">
        <img src={skate_mural} className="img-fluid header-img-BHS" alt="mock-up of d'metria smith mural"/>
        <div className="header-BHS">
          Boyes Hot Springs Mural featuring D'mitra Smith
        </div>

        <div className="text-columns-BHS">
        <div className="text-box-BHS">
        &emsp; <b>D’mitra</b> is a Black, Indigenous bi-racial, queer WOC.
        D'mitra Smith is the former Chair of the Sonoma County Commission on Human Rights, serving from 2012 to August 1, 2020. She was the program
        manager for the Junior Commission, having mentored over 100 Sonoma County students between 2013- 2020. Smith is a <b>co-founder</b> of the essential
        workers' mutual aid collective Food for All - Comida para Todos, and Save Your VI, providing education and advocacy for civil rights protections
        guaranteed to students nationwide.

        <br /><br />
        &emsp; <b>She is the author</b> of the first county report on racial discrimination in Sonoma County schools and co-author of "Human Rights
        Violations in Santa Rosa: <b>Policing the Black Lives Matter Protests</b>".  She has worked with the California Association of Human Relations
        Organizations, Congressional Black Caucus Education and Workforce Committee, California NAACP, San Jose State University Human Rights
        Institute, Southern Poverty Law Center, John Marshall Law School, and the US Department of Education Office for Civil Rights.

        <br /><br />
        &emsp; <b>Jordan</b>, the owner of <b>Sonoma Originals</b>, a skate shop and community hangout spot for youth at <b>17400 Sonoma Hwy</b>, offered us the
        large wall on the side of the building as a canvas.
        </div>

        <div className="text-box-BHS-right">
        &emsp;  <b>If</b> you <b>appreciate</b> the mural and are able to contribute, we need <b>support</b> to cover the outstanding cost of the paint, materials,
        renting scaffolding and paying the artists for their hard work and creativity.
        <br /><br />
        <a href="https://gofund.me/8b684030">DONATE on our Go Fund Me Page</a>
        </div>


        <div className="text-box-BHS-right green-border">
          <img src={mural} className="img-fluid" alt="Rema and Dmitria looking at mural in progress" />
        </div>

        </div>
      </div>
    </div>

  );
}
